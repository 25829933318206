<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Time-sheets</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" text to="/time-sheet/create">
          <v-icon left dark>mdi-plus</v-icon>New time-sheet
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-text-field dense v-model="search" label="Search"></v-text-field>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              dense
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="timeSheets"
              :search="search"
              item-key="id"
              :single-select="singleSelect"
            >
              <template v-slot:item.id="{ item }">
                <v-btn color="primary" text :to="`/time-sheet/${item.id}`">{{
                  item.id
                }}</v-btn>
              </template>

              <template v-slot:item.Status="{ item }">
                <v-btn
                  class="btnLink"
                  color="accent"
                  text
                  @click="changeStatus(item)"
                  >{{ item.Status == 1 ? "Active" : "In Active" }}</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- snackbar -->
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    timeSheets: [],
    loader: false,
    dialog: false,
    singleSelect: true,
    headers: [
      { text: "Code", value: "Code" },
      { text: "Name", value: "Name" },
      { text: "CheckInTime", value: "CheckInTime" },
      { text: "CheckOutTime", value: "CheckOutTime" },
      // { text: "LogType", value: "LogType" },
      { text: "Status", value: "Status" },
      { text: "Action", value: "id" },
    ],
  }),
  methods: {
    getTimeSheets() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/timesheets-master-data`)
        .then((res) => {
          self.timeSheets = res.ResponseData;
          console.log(res, "res");
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getTimeSheets();
  },
};
</script>